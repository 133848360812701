import { errorMessage } from "../../../../utils/ErrorMessages";
import Repository from "../../../../services/repositories/RepositoryFactory";

const accessorials = Repository.service("accessorials");
const loadAccessorials = Repository.service("loadAccessorials");
const load = Repository.service("load");

export default {
  namespaced: true,
  state: {
    accessorials: {},
    chosenAccessorials: [],
    carriersInfoAccessorials: [],
    allAccessorialsByLoad: [],
    moreAccessorials: {},
    classifiedAccessorials: {},
    isHazmatLoad: false,
    selectedCommonAccessorials: {},
    defaultCheckedAccessorials: []
  },
  getters: {
    getAccessorials: (state) => (departmentId) => state.accessorials[departmentId],
    getChosenAccessorials(state) {
      return state.chosenAccessorials;
    },
    getAllAccessorialsByLoad(state) {
      return state.allAccessorialsByLoad;
    },
    getMoreAccessorials(state) {
      return state.moreAccessorials;
    },
    getCarriersInfoAccessorials(state) {
      return state.carriersInfoAccessorials;
    },
    getIsHazmatLoad(state) {
      return state.isHazmatLoad;
    },
    getClassifiedAccessorials: (state) => state.classifiedAccessorials,
    getSelectedCommonAccessorials: (state) => state.selectedCommonAccessorials,
    getDefaultCheckedAccessorials(state) {
      return state.defaultCheckedAccessorials;
    }
  },
  mutations: {
    setAccessorials(state, payload) {
      state.accessorials[payload.departmentId] = payload.response;
    },
    setLoadChosenAccessorials(state, payload) {
      state.chosenAccessorials = [...payload];
    },
    setChosenAccessorials(state, payload) {
      if (payload.accessorial_type !== "general") {
        payload.related_name = payload.accessorial_type.replace(" ", "");
      }
      state.chosenAccessorials.push(payload);
    },
    deleteFromChosenAccessorials(state, payload) {
      state.chosenAccessorials = state.chosenAccessorials.filter(
        (accessorial) => accessorial.id !== payload.id
      );
    },
    changeDataHazmat(state, payload) {
      state.isHazmatLoad = payload;
    },
    setAllAccessorialsByLoad(state, payload) {
      state.allAccessorialsByLoad = payload;
    },
    setMoreAccessorials(state, payload) {
      state.moreAccessorials = payload;
    },
    resetChosenAccessorials(state) {
      state.chosenAccessorials = [];
      state.selectedCommonAccessorials = {};
    },
    setCarriersInfoAccessorials(state, payload) {
      state.carriersInfoAccessorials = payload;
    },
    classifyAccessorials(state, departmentId) {
      if (!state.classifiedAccessorials[departmentId]) {
        state.classifiedAccessorials[departmentId] = {};
        const accessorialsType = ["general", "pickup", "delivery"];
        accessorialsType.forEach((key) => {
          state.classifiedAccessorials[departmentId][key] = state.accessorials[departmentId].filter(
            (item) => item.accessorial_type.replace(" ", "") === key
          );
        });
      }
    },
    assignRevenovaId(state, payload) {
      payload.accessorials.forEach((accessorial) => {
        const foundAccessorial = state.accessorials[payload.departmentId].find(
          (el) => el.id === accessorial.accessorial_id
        );
        if (foundAccessorial) {
          foundAccessorial.revenova_id = accessorial.id;
        }
      });
    },
    setDefaultCheckedAccessorials(state, payload) {
      state.defaultCheckedAccessorials = payload;
    }
  },
  actions: {
    async getAllAccessorials({ commit, rootGetters, getters }, departmentId) {
      try {
        let response = getters.getAccessorials(departmentId);
        if (!response) {
          response = await accessorials.getAllAccessorials(departmentId);
        }
        commit("setAccessorials", { response, departmentId });
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },

    /// load-accessorials \\\

    async getAllLoadAccessorials({ rootGetters }) {
      try {
        const response = await loadAccessorials.getAllLoadAccessorials();
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async createLoadAccessorials({ rootGetters }, body) {
      try {
        const response = await loadAccessorials.createLoadAccessorials(body);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async getLoadAccessorialsById({ rootGetters }, id) {
      try {
        const response = await loadAccessorials.getLoadAccessorialsById(id);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async deleteLoadAccessorials({ rootGetters }, id) {
      try {
        const response = await loadAccessorials.deleteLoadAccessorials(id);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async updateLoadAccessorials({ rootGetters }, body) {
      try {
        const response = await loadAccessorials.updateLoadAccessorials(body);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },

    /// accessorials by load \\\

    async getAllAccessorialsByLoad({ commit, rootGetters }, loadId) {
      try {
        const response = await load.getAllAccessorialsByLoad(loadId);
        commit("setAllAccessorialsByLoad", response);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async createAccessorialsByLoad({ commit, rootGetters }, data) {
      try {
        const response = await load.createAccessorialsByLoad(data);
        commit("assignRevenovaId", {
          departmentId: data.departmentId,
          accessorials: [response.new_accessorial],
        });
        commit("classifyAccessorials", data.departmentId);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
    async deleteAccessorialsByLoad({ rootGetters }, data) {
      try {
        const response = await load.deleteAccessorialsByLoad(data);
        return response;
      } catch (error) {
        return errorMessage(error.response.data.detail.errors[0][rootGetters.getLanguage]);
      }
    },
  },
};
